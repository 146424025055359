<template>
  <div
    v-show="isWidgetEnabled"
    ref="draggableRef"
    :style="draggableStyle"
    style="
      box-sizing: border-box;
      position: fixed;
      touch-action: none;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      z-index: 99999999;
    "
  >
    <div>
      <IconMove
        v-if="!isMobile && !expanded"
        style="
          padding: 0.4rem;
          color: rgb(107, 114, 128);
          background-color: rgb(255, 255, 255);
          box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
            rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
          border-radius: 0.5rem;
          margin-right: 14px;
          cursor: grab;
          width: 2rem;
          height: 2rem;
        "
      />
    </div>

    <div
      :class="{ expanded, isMobile: isMobile }"
      :style="widgetFrameStyle"
      class="call24-wf"
      style="border-radius: 8px"
    >
      <iframe
        ref="ifr"
        :class="{ expanded, isMobile: isMobile }"
        :src="frameUrl"
        :style="widgetPosStyle"
        allow="camera; microphone; display-capture; autoplay; clipboard-write; geolocation;"
        class="call24-wf"
      ></iframe>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useIsMobile } from "@/helpers/utils";
import { WidgetOptions } from "@/index";
import { usePreviewer } from "@/plugins/preview";
import {
  useBoundaryDraggable,
  XYPosition,
} from "@/helpers/useBoundaryDraggable";
import { useWindowSize, watchImmediate } from "@vueuse/core";
import IconMove from "@/components/Icon/IconMove.vue";

const props = defineProps<{
  widgetOptions: WidgetOptions;
}>();

const { openPreview } = usePreviewer();

const ifr = ref<HTMLIFrameElement | undefined>(undefined);
const expanded = ref(false);

function sendViewportDetails() {
  ifr.value?.contentWindow?.postMessage(
    {
      source: "call24",
      type: "call24:resize",
      width: window.innerWidth,
    },
    "*"
  );
}

const welcomeMessageHeight = ref(60);
const screenOffset = 32;

const desktopWidgetSize = {
  width: 300,
  height: 100,
};

const mobileWidgetSize = {
  width: 64,
  height: 100,
};

const isMobile = useIsMobile();
const windowSize = useWindowSize();

watchImmediate(windowSize.width, () => sendViewportDetails());

const widgetSize = computed(() => {
  return isMobile.value ? mobileWidgetSize : desktopWidgetSize;
});

const containerRect = ref({
  width: 0,
  height: 0,
});

const initSize = computed<XYPosition>(() => {
  if (expanded.value) {
    let yOffset = 720;

    return {
      x: windowSize.width.value - screenOffset - 450,
      y: Math.max(
        windowSize.height.value - screenOffset * 2 - yOffset,
        screenOffset / 2
      ),
    };
  }

  return {
    x: windowSize.width.value - screenOffset - containerRect.value.width,
    y: windowSize.height.value - screenOffset - containerRect.value.height,
  };
});

const draggableRef = ref<HTMLDivElement>();

const { style: draggableStyle, isDragging } = useBoundaryDraggable(
  draggableRef,
  {
    initialValue: {
      x: windowSize.width.value - widgetSize.value.width - screenOffset,
      y: windowSize.height.value - widgetSize.value.height - screenOffset,
    },
    widgetSize: widgetSize,
    screenOffset,
  }
);

const widgetPosStyle = computed(() => {
  if (expanded.value) {
    if (isMobile.value) {
      return {
        position: "fixed",
        height: `${welcomeMessageHeight.value}px`,
      };
    } else {
      return {
        position: "fixed",
        top: `${initSize.value.y}px`,
        left: `${initSize.value.x}px`,
        height: `${welcomeMessageHeight.value}px`,
      };
    }
  } else {
    return {
      height: `${welcomeMessageHeight.value}px`,
    };
  }
});

const widgetFrameStyle = computed(() => {
  const merge = widgetPosStyle.value;

  if (!isMobile.value && expanded.value) {
    return {
      ...merge,
      boxShadow: "0 0 3px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 0, 0, 0.25)",
    };
  } else {
    return {
      ...merge,
    };
  }
});

function calculateRect() {
  if (draggableRef.value) {
    containerRect.value = {
      width: draggableRef.value.clientWidth,
      height: draggableRef.value.clientHeight,
    };
  }
}

const isWidgetEnabled = ref(false);

onMounted(() => {
  if (ifr.value?.contentWindow) {
    window.Call24.API.setWindow(ifr.value.contentWindow);
  }

  calculateRect();
});

window.addEventListener("message", (e) => {
  if (e.data.type === "call24:widget-enabled") {
    isWidgetEnabled.value = e.data.enabled;
  } else if (e.data === "ask:size") {
    sendViewportDetails();
  } else if (typeof e.data === "object" && e.data.source === "call24")
    if (e.data.type === "call24:expand") {
      expanded.value = e.data.expanded;
    } else if (e.data.type === "call24:welcome-message:height" && ifr.value) {
      welcomeMessageHeight.value = e.data.height;
    } else if (e.data.type === "call24:preview-image") {
      openPreview(e.data.url);
    }
});

const url = new URL(window.Call24.PageUrl);

const initialViewport = document.querySelector('meta[name="viewport"]');
const call24Viewport = document.createElement("meta");
call24Viewport.name = "viewport";
call24Viewport.content =
  "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0, interactive-widget=resizes-content";

watchImmediate(expanded, (expaded) => {
  if (isMobile.value) {
    if (expaded) {
      document.head.querySelector('meta[name="viewport"]')?.remove();
      document.head.appendChild(call24Viewport);
    } else {
      document.head.querySelector('meta[name="viewport"]')?.remove();

      if (initialViewport) document.head.appendChild(initialViewport);
    }
  }
});

watch([expanded, isMobile], () => {
  const lock = expanded.value && isMobile.value;
  document.body.classList.toggle("call24-lock-scroll", lock);
});

const frameUrl = `${
  url.origin + process.env.VUE_APP_IFRAME_WIDGET_PATH
}?project=${window.CALL24_ID}&options=${encodeURIComponent(
  JSON.stringify(props.widgetOptions)
)}`;

watch(isDragging, (val) => {
  if (val) {
    document.body.style.userSelect = "none";
  } else {
    document.body.style.userSelect = "";
  }
});

watch(expanded, () => {
  if (expanded.value) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }
});
</script>
